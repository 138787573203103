import React from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/button/Button';
import { Card } from '@/components/card/Card';
import { Row } from '@/components/layout/Flex';
import { Typo } from '@/components/typography/Text';
import { useStyles } from '@/hooks/useStyles';
import { ValidateOrRejectIdentity } from '@/models/identity/FrenchIdentity';
import { IdentityValidation } from '@/models/identity/IdentityValidationModel';
import { toDateTime } from '@/utils/date.ts';

export type ValidatePatientCardProps = {
  identity: IdentityValidation;
  submitting: boolean;
  validateOrReject: (action: ValidateOrRejectIdentity) => void;
};

export const ValidatePatientCard: React.FC<ValidatePatientCardProps> = ({
  identity,
  submitting,
  validateOrReject,
}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const birthLocation = identity.franceBirthLocation
    ? `${
        identity.franceBirthLocation?.town
      } (${identity.franceBirthLocation?.post_code.slice(0, 2)})`
    : identity.franceBirthCountry;

  return (
    <Card elevation={2} className={styles.item}>
      <Typo type="subtitle" className={styles.itemTitle}>{`${
        identity.givenName ?? ''
      } ${identity.familyName}`}</Typo>
      <table>
        <tbody>
          <PatientLine
            labelClassname={styles.lineLabel}
            label={t('profile.identityFirstnames')}
            value={identity.identityFirstnames.join(', ')}
          />
          <PatientLine
            labelClassname={styles.lineLabel}
            label={t('profile.identityLastname')}
            value={identity.identityLastname}
          />
          <PatientLine
            labelClassname={styles.lineLabel}
            label={t('profile.gender')}
            value={
              identity.gender === 'male'
                ? t('profile.male')
                : t('profile.female')
            }
          />
          <PatientLine
            labelClassname={styles.lineLabel}
            label={t('profile.birthDate')}
            value={toDateTime(identity.birthDate).toLocaleString(
              DateTime.DATE_SHORT,
            )}
          />
          {birthLocation ? (
            <PatientLine
              labelClassname={styles.lineLabel}
              label={t('profile.birthPlace')}
              value={birthLocation}
            />
          ) : null}
        </tbody>
      </table>
      <Row className={styles.actions}>
        <Button
          status={submitting ? 'pending' : 'idle'}
          buttonType="critical"
          onClick={() => validateOrReject('reject')}
        >
          {t('button.reject')}
        </Button>
        <Button
          status={submitting ? 'pending' : 'idle'}
          buttonType="primary"
          onClick={() => validateOrReject('validate')}
        >
          {t('button.accept')}
        </Button>
      </Row>
    </Card>
  );
};

export type PatientLineProps = {
  label: string;
  value: string;
  labelClassname?: string;
};
const PatientLine: React.FC<PatientLineProps> = ({
  label,
  value,
  labelClassname,
}) => {
  return (
    <tr>
      <td className={labelClassname}>
        <Typo type="paragraph">{label}</Typo>
      </td>
      <td>
        <Typo type="paragraph">{value}</Typo>
      </td>
    </tr>
  );
};

const makeStyles = (theme: Theme) => ({
  item: css`
    padding: ${theme.spacing(12)};
    margin-bottom: ${theme.spacing(8)};
  `,
  itemTitle: css`
    padding-bottom: ${theme.spacing(8)};
  `,
  lineLabel: css`
    padding-right: ${theme.spacing(12)};
  `,
  list: css`
    padding: ${theme.spacing(8)} ${theme.spacing(2)};
    gap: ${theme.spacing(8)};
  `,
  actions: css`
    padding-top: ${theme.spacing(12)};
    gap: ${theme.spacing(12)};
    justify-content: flex-end;
  `,
  reject: css``,
});
