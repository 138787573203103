import React from 'react';

import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons';
import { Calendar } from '@/components/calendar/Calendar';
import { MenuTooltip } from '@/components/floating/MenuTooltip';
import { useStyles } from '@/hooks/useStyles';
import { DateUtils, toDateTime } from '@/utils/date.ts';

type DayPickerProps = {
  date: DateTime<true>;
  setDate: React.Dispatch<DateTime<true>>;
};

export const DayPicker: React.FC<DayPickerProps> = ({ date, setDate }) => {
  const styles = useStyles(makeDayPickerStyles);
  const { i18n } = useTranslation();

  const decrementPeriodicity = () => {
    const newDate: DateTime<true> = date.minus({ days: 1 });
    setDate(newDate);
  };

  const incrementPeriodicity = () => {
    if (dateIsToday) return;

    const newDate: DateTime<true> = date.plus({ days: 1 });
    setDate(newDate);
  };

  const dateIsToday = DateUtils.dateEquals(date, DateTime.now());

  return (
    <div className={styles.container}>
      <SelectPreviousRange onClick={decrementPeriodicity} />
      <MenuTooltip
        placement="bottom"
        wrapperClassName={styles.tooltipWrapper}
        closeOnInteract={false}
        content={
          <Calendar
            className={styles.calendar}
            date={date.toJSDate()}
            refDate={date.toJSDate()}
            onDateChange={(value: Date) => {
              const dt = toDateTime(value);
              if (dt.isValid) {
                setDate(dt);
              }
            }}
            tileDisabled={({ date: day }) => day > new Date()}
            showNeighboringMonth={false}
            minDetail="year"
            locale={i18n.language}
          />
        }
      >
        <button className={styles.date}>
          {date.toLocaleString(DateTime.DATE_SHORT)}
        </button>
      </MenuTooltip>

      <SelectNextRange disabled={dateIsToday} onClick={incrementPeriodicity} />
    </div>
  );
};

const makeDayPickerStyles = (theme: Theme) => ({
  container: css`
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: center;
  `,
  tooltipWrapper: css`
    height: 100%;
    display: flex;
    justify-content: stretch;
  `,
  date: css`
    width: ${theme.spacing(96)};
    padding: ${theme.spacing(4)} ${theme.spacing(6)};
    margin: 0 ${theme.spacing(4)};
    text-align: center;
    user-select: none;
    background-color: ${theme.palette.common.white};
  `,

  calendar: css`
    background-color: ${theme.palette.common.white};
    padding: ${theme.spacing(4)};
    width: min(${theme.spacing(160)}, 95vw);
    min-width: ${theme.spacing(96)};
  `,
});

const SelectPreviousRange = ({
  onClick,
}: {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) => {
  const styles = useStyles(makeSelectRangeStyles, false);
  return (
    <button className={styles.button} onClick={onClick}>
      <Icons.arrowLeft />
    </button>
  );
};

const SelectNextRange = ({
  onClick,
  disabled,
}: {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled: boolean;
}) => {
  const styles = useStyles(makeSelectRangeStyles, disabled);

  return (
    <button className={styles.button} disabled={disabled} onClick={onClick}>
      <Icons.arrowRight />
    </button>
  );
};

const makeSelectRangeStyles = (theme: Theme, disabled: boolean) => ({
  button: css`
    background-color: ${theme.palette.common.white};
    padding: ${theme.spacing(6)};
    color: ${disabled ? theme.palette.grey[300] : theme.palette.text.primary};
  `,
});
