import { DateTime } from 'luxon';

import { BaseTooltip } from '@/components/floating/BaseTooltip';
import { BGMContentrationType } from '@/models/BGMLogbook';
import { toDateTime } from '@/utils/date.ts';

export const Insulin = ({ data }: { data: BGMContentrationType[] }) => {
  const insulinFormatted = data.map(el => el.concentration).join('+');
  const timeFormatted = data
    .map(el => toDateTime(el.date).toLocaleString(DateTime.TIME_SIMPLE))
    .join('+');
  return (
    <BaseTooltip
      type="hover"
      placement="top"
      content={timeFormatted}
      role="tooltip"
    >
      <span>{insulinFormatted}</span>
    </BaseTooltip>
  );
};
