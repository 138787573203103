import { css } from '@emotion/css';
import { Theme } from '@mui/material';

import { Loader } from '@/components/loading/Loader';
import { useStyles } from '@/hooks/useStyles';
import { BGMContentrationType } from '@/models/BGMLogbook';
import { usePatientMonitoringContext } from '@/pages/patient-monitoring/PatientMonitoringState.tsx';
import { Queries } from '@/queries/Queries.ts';

import { GlycemiaBadge } from './GlycemiaBadge';

export const GlycemiaBadges = ({ data }: { data: BGMContentrationType[] }) => {
  const styles = useStyles(makeStyles);
  const patientId = usePatientMonitoringContext(state => state.patient.id);
  const glycemiaParameters = Queries.diabetes.useParameters(patientId);

  return (
    <div className={styles.container}>
      {glycemiaParameters.isSuccess ? (
        data.map((val, i) => (
          <div key={i}>
            <GlycemiaBadge
              glycemiaValue={val}
              thresholds={glycemiaParameters.data}
            ></GlycemiaBadge>
          </div>
        ))
      ) : (
        <Loader size="S" />
      )}
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: ${theme.spacing(2)};
  `,
});
