import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Typo } from '@/components/typography/Text';
import { useStyles } from '@/hooks/useStyles';
import { toDateTime } from '@/utils/date.ts';

type DateInCellProps = {
  date: string;
};

export const PrettyDate = ({ date }: DateInCellProps) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();
  if (date.includes('avg')) {
    return (
      <div className={styles.container}>
        <Typo className={styles.date} type="paragraph">
          {t('bgmLogBook.average')}
        </Typo>
      </div>
    );
  }
  const dt = toDateTime(date);
  const day = dt.toFormat('EEEE').capitalize();
  const completeDate = dt.toFormat('dd/MM/yy');

  return (
    <div className={styles.container}>
      <Typo className={styles.date} type="paragraph">
        {day}
      </Typo>
      <Typo className={styles.date} type="paragraph">
        {completeDate}
      </Typo>
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  date: css`
    color: ${theme.palette.violet.main};
  `,
  container: css`
    margin: auto;
    display: flex;
    flex-direction: column;
  `,
});
