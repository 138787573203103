import { DateTime, Duration, DurationUnit, SystemZone } from 'luxon';

/**
 * Supported date types
 * - string: ISO 8601 date string
 * - Date: JavaScript Date object
 * - DateTime: Luxon DateTime object
 * - undefined: No date provided
 */
export type SupportedDate = string | Date | DateTime | undefined | number;

export type PluralDurationUnit =
  | 'years'
  | 'quarters'
  | 'months'
  | 'weeks'
  | 'days'
  | 'hours'
  | 'minutes'
  | 'seconds'
  | 'milliseconds';

const unitToPlural = (unit: DurationUnit): PluralDurationUnit => {
  switch (unit) {
    case 'year':
      return 'years';
    case 'quarter':
      return 'quarters';
    case 'month':
      return 'months';
    case 'week':
      return 'weeks';
    case 'day':
      return 'days';
    case 'hour':
      return 'hours';
    case 'minute':
      return 'minutes';
    case 'second':
      return 'seconds';
    case 'millisecond':
      return 'milliseconds';
  }
  return unit;
};

const compareUnits = (unit1: DurationUnit, unit2: DurationUnit): number => {
  const units = [
    'years',
    'quarters',
    'months',
    'weeks',
    'days',
    'hours',
    'minutes',
    'seconds',
    'milliseconds',
  ];

  return units.indexOf(unit1) - units.indexOf(unit2);
};

const highestDurationUnit = (
  d: Duration,
  limit: DurationUnit,
): DurationUnit => {
  let highestUnit: DurationUnit = 'milliseconds';
  switch (true) {
    case d.years !== 0:
      highestUnit = 'years';
      break;
    case d.quarters !== 0:
      highestUnit = 'quarters';
      break;
    case d.months !== 0:
      highestUnit = 'months';
      break;
    case d.weeks !== 0:
      highestUnit = 'weeks';
      break;
    case d.days !== 0:
      highestUnit = 'days';
      break;
    case d.hours !== 0:
      highestUnit = 'hours';
      break;
    case d.minutes !== 0:
      highestUnit = 'minutes';
      break;
    case d.seconds !== 0:
      highestUnit = 'seconds';
      break;
  }

  if (compareUnits(highestUnit, limit) > 0) {
    return limit;
  }

  return highestUnit;
};

const nextDurationUnit = (d: DurationUnit): DurationUnit => {
  const unit = unitToPlural(d);
  switch (unit) {
    case 'years':
      return 'quarters';
    case 'quarters':
      return 'months';
    case 'months':
      return 'weeks';
    case 'weeks':
      return 'days';
    case 'days':
      return 'hours';
    case 'hours':
      return 'minutes';
    case 'minutes':
      return 'seconds';
    case 'seconds':
      return 'milliseconds';
    case 'milliseconds':
      return 'milliseconds';
  }
};

export type ShiftOptions = {
  /**
   * Add the next unit if the highest unit is 1
   *
   * @default true
   */
  addNextUnit?: boolean;

  /**
   * Floor the values
   *
   * @default true
   */
  floor?: boolean;
  limit?: DurationUnit;
};

/**
 * Shift a duration to the highest unit that is not zero
 * Adds the next unit if the highest unit ha a value of 1
 * @example
 * shiftDurationToHighestUnit({ hours: 1, minutes: 30 }) // { hours: 1, minutes: 30 }
 * shiftDurationToHighestUnit({ hours: 2, minutes: 30 }) // { hours: 2 }
 * shiftDurationToHighestUnit({ hours: 1 }) // { hours: 1 }
 * @param d
 * @param options
 */
const shiftDurationToHighestUnit = (d: Duration, options?: ShiftOptions) => {
  // Invert the duration if it is negative
  d = d.shiftTo('milliseconds');
  if (d.milliseconds < 0) {
    d = d.negate();
  }

  // Rescale the duration to use proper units
  d = d.rescale();

  // Find the highest unit that is not zero (returns limit if highest unit is below limit)
  const limit = options?.limit ?? 'minutes';
  const highestUnit = highestDurationUnit(d, 'minutes');
  const nextUnit =
    highestUnit === limit ? limit : nextDurationUnit(highestUnit);

  const addNextUnit = options?.addNextUnit ?? true;
  const units =
    addNextUnit && d.get(highestUnit) === 1 && d.get(nextUnit) > 0
      ? [highestUnit, nextDurationUnit(highestUnit)]
      : [highestUnit];
  d = d.shiftTo(...units);

  const floor = options?.floor ?? true;
  d = floor ? d : d.mapUnits(x => Math.floor(x));

  return d;
};

const humanDurationFromNow = (d: SupportedDate, options?: ShiftOptions) => {
  return DateUtils.shiftDurationToHighestUnit(
    toDateTime(d).diffNow(),
    options,
  ).toHuman({ listStyle: 'long' });
};

/**
 * Convert a date to a DateTime object
 */
export const toDateTime = (date: SupportedDate) => {
  if (DateTime.isDateTime(date)) {
    return date;
  }
  if (typeof date === 'string') {
    return DateTime.fromISO(date);
  }
  if (typeof date === 'number') {
    return DateTime.fromMillis(date);
  }
  if (date === undefined) {
    return DateTime.invalid('No provided date');
  }
  return DateTime.fromJSDate(date);
};

/**
 * Check if two dates are equal
 * Ignore time and only compare the date
 */
const datesAreEqual = (date1: SupportedDate, date2: SupportedDate) => {
  return toDateTime(date1).hasSame(toDateTime(date2), 'day');
};

/**
 * Compare two dates
 * Return the difference in days between the two dates
 * Positive if date1 is after date2
 * Negative if date1 is before date2
 * Zero if the dates are the same
 * @param date1
 * @param date2
 */
const compareDates = (date1: SupportedDate, date2: SupportedDate) => {
  return toDateTime(date1).diff(toDateTime(date2), ['days', 'hours']).days;
};

const compareDateTimes = (date1: SupportedDate, date2: SupportedDate) => {
  return toDateTime(date1).diff(toDateTime(date2)).as('milliseconds');
};

/**
 * Get the age of a person based on their birthdate
 */
const getAge = (date: SupportedDate) => {
  return Math.floor(DateTime.now().diff(toDateTime(date), 'years').as('years'));
};

/**
 * Get the timezone offset (in minutes) at a given date
 */
const getTimeZoneOffsetMinutes = (date: SupportedDate) => {
  return SystemZone.instance.offset(toDateTime(date).toMillis());
};

export const DateUtils = {
  dateEquals: datesAreEqual,
  age: getAge,
  compareDates: compareDates,
  compareDateTimes: compareDateTimes,
  tzOffset: getTimeZoneOffsetMinutes,
  shiftDurationToHighestUnit: shiftDurationToHighestUnit,
  humanDurationFromNow: humanDurationFromNow,
};
