import React, { useLayoutEffect, useMemo } from 'react';

import { css } from '@emotion/css';
import { Theme, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Card } from '@/components/card/Card';
import { CardBody } from '@/components/card/CardBody';
import { DailyGraph } from '@/components/data-representations/DailyGraph/DailyGraph';
import { DayPicker } from '@/components/period-picker/DayPicker';
import { useQueryDate } from '@/hooks/query/useQueryDate.ts';
import { usePatientIdFromURL } from '@/hooks/usePatientIdFromURL.ts';
import { useStyles } from '@/hooks/useStyles';
import { useWindowSize } from '@/hooks/useWindowSize';
import { Queries } from '@/queries/Queries';
import { DateUtils } from '@/utils/date.ts';

const emptyPayload = {
  patientId: '',
  from: DateTime.now(),
  to: DateTime.now(),
};

export const PatientData: React.FC = () => {
  const { t } = useTranslation();
  const date = useQueryDate();
  const patientId = usePatientIdFromURL();
  const [width, setWidth] = React.useState(0);
  const windowSize = useWindowSize();
  const divRef = React.useRef<HTMLDivElement>(null);
  const styles = useStyles(makeStyles);
  const [, setSearchParams] = useSearchParams();

  const dataviz = Queries.diabetes.useDataviz(
    patientId
      ? {
          patientId: patientId,
          from: date,
          to: date,
        }
      : emptyPayload, // unused
    {
      enabled: !!patientId,
    },
  );

  useLayoutEffect(() => {
    if (divRef.current) {
      setWidth(divRef.current.offsetWidth);
    }
  }, [windowSize.width]);

  const handleDateChange = (newDate: DateTime<true>) => {
    if (patientId) {
      setSearchParams({ date: newDate.toISODate() });
    }
  };

  const dailyData = useMemo(() => {
    if (dataviz.isSuccess) {
      const data = dataviz.data[0];
      return {
        ...data,
        glycemiaTypes: {
          glycemiaSensor: data.glycemia
            .filter(glycemia => glycemia.type === 'interstitial')
            .sort((a, b) => DateUtils.compareDateTimes(a.date, b.date)),
          glycemiaReader: data.glycemia.filter(
            glycemia => glycemia.type === 'capillary',
          ),
          glycemiaManual: [],
        },
      };
    } else {
      return undefined;
    }
  }, [dataviz.data, dataviz.isSuccess, patientId]);

  if (!patientId) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography variant="h4">{t('patientNavigation.dailyView')}</Typography>
        <DayPicker setDate={handleDateChange} date={date} />
      </div>
      <Card elevation={0}>
        <CardBody htmlRef={divRef}>
          {dailyData ? (
            <DailyGraph {...{ data: dailyData }} width={width * 0.95} />
          ) : null}
        </CardBody>
      </Card>
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: column;
    flex: 1 1;
    gap: ${theme.spacing(8)};
  `,
  header: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `,
});
