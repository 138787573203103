import React, { useEffect } from 'react';

import { css } from '@emotion/css';
import { Theme, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FontSizes } from '@/assets/fonts';
import { Form } from '@/components/form/Form';
import { RadioFormInput } from '@/components/form/inputs/RadioFormInput';
import { Col } from '@/components/layout/Flex';
import { GridItem } from '@/components/layout/Grid';
import { FileView } from '@/components/pdf/FileView';
import { Typo } from '@/components/typography/Text';
import { useStyles } from '@/hooks/useStyles';
import { CarePlan } from '@/models/CarePlanModel';
import { Condition } from '@/models/ConditionModel';
import { PatientsTagsList } from '@/pages/patients/PatientsTagsList';
import { Queries } from '@/queries/Queries';
import { toDateTime } from '@/utils/date.ts';

import { EditTherapeuticObjectives } from './EditTherapeuticObjectives';

type CurrentCarePlanProps = {
  patientId: string;
  carePlan: CarePlan;
};

type FormType = {
  diabetesType: string;
  insulinScheme: string;
  telemonitoringTier: string;
};

export const CurrentCarePlan: React.FC<CurrentCarePlanProps> = ({
  patientId,
  carePlan,
}) => {
  const fakeForm = useForm<FormType>();
  const styles = useStyles(makeStyles);
  const { data: condition } = Queries.condition.useCondition(patientId);

  useEffect(() => {
    if (carePlan && condition) {
      fakeForm.setValue('diabetesType', condition.type);
      fakeForm.setValue('insulinScheme', condition.medicationSchema);
      fakeForm.setValue('telemonitoringTier', carePlan.telemonitoringTier);
    }
  }, [fakeForm, carePlan]);

  return (
    <Form
      id="FAKE_DIABETES_FORM"
      formContext={fakeForm}
      onSubmit={() => {
        // Empty
      }}
      className={styles.grid}
    >
      <GridItem row={1} col={1}>
        <TelemonitoringInfo patientId={patientId} />
      </GridItem>
      <GridItem row={1} col={2}>
        <TelemonitoringDatesInfo carePlan={carePlan} />
      </GridItem>
      <GridItem row={1} col={3}>
        <ResponsiblePractitionerInfo patientId={patientId} />
      </GridItem>
      <GridItem row={2} col={1}>
        {condition ? <DiabetesTypeInfo condition={condition} /> : null}
      </GridItem>
      <GridItem row={2} col={2}>
        {condition ? <InsulinSchemeInfo condition={condition} /> : null}
      </GridItem>
      <GridItem row={2} col={3}>
        <TelemonitoringCriteriaInfo carePlan={carePlan} />
      </GridItem>
      <GridItem row={3} col={`1 / span 2`}>
        <TelemonitoringGoalsInfo carePlan={carePlan} />
      </GridItem>
      <GridItem row={3} col={3}>
        <PrescriptionFileInfo carePlan={carePlan} />
      </GridItem>
    </Form>
  );
};

type TelemonitoringInfoProps = {
  patientId: string;
};
export const TelemonitoringInfo: React.FC<TelemonitoringInfoProps> = ({
  patientId,
}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const { data: carePlanData } = Queries.practitioner.useCarePlans(patientId);
  if (!carePlanData) {
    return null;
  }

  return (
    <Col>
      <Typography variant="subtitle" className={styles.labelStyle}>
        {t('diabetesForms.telemonitoring')}
      </Typography>
      <PatientsTagsList telemonitoringTags={carePlanData.telemonitoring_tags} />
    </Col>
  );
};

type ResponsiblePractitionerInfo = {
  patientId: string;
};

export const ResponsiblePractitionerInfo: React.FC<
  ResponsiblePractitionerInfo
> = ({ patientId }) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const { data: careTeam } = Queries.practitioner.useCareTeam(patientId);
  const { data: practitioner } = Queries.practitioner.usePractitioner({});

  const participants = careTeam?.participants;

  if (!participants || !practitioner) {
    return null;
  }

  const nurses = participants.filter(
    participant => participant.role === 'nurse',
  );
  const doctor = participants.find(
    participant => participant.role === 'doctor',
  );

  return (
    <Col>
      <Typography variant="subtitle" className={styles.labelStyle}>
        {practitioner.qualification === 'nurse'
          ? t('diabetesForms.responsibleDoctor')
          : t('diabetesForms.responsibleNurse')}
      </Typography>
      {practitioner.qualification === 'nurse' ? (
        doctor ? (
          <Typography variant="body">{`${doctor.givenName} ${doctor.familyName}`}</Typography>
        ) : null
      ) : nurses.length > 0 ? (
        <Typo type="paragraph">
          {nurses
            .map(nurse => `${nurse.givenName} ${nurse.familyName}`)
            .join(', ')}
        </Typo>
      ) : null}
    </Col>
  );
};

type InfoProps = {
  carePlan?: CarePlan;
};

type ConditionProps = {
  condition: Condition;
};

export const DiabetesTypeInfo: React.FC<ConditionProps> = ({ condition }) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  return (
    <RadioFormInput<Condition>
      options={[
        {
          id: 'fake-type_1',
          value: 'type1',
          label: t('diabetesForms.diabetesType1'),
          hidden: condition.type !== 'type1',
        },
        {
          id: 'fake-type_2',
          value: 'type2',
          label: t('diabetesForms.diabetesType2'),
          hidden: condition.type !== 'type2',
        },
        {
          id: 'fake-gestational',
          value: 'gestational',
          label: t('diabetesForms.diabetesGestational'),
          hidden: condition.type !== 'gestational',
        },
        {
          id: 'fake-undef',
          value: undefined,
          label: t('condition.unspecified'),
          hidden: condition.type !== undefined,
        },
      ]}
      label={t('diabetesForms.diabetesType')}
      labelStyle={styles.labelStyle}
      name="type"
      disabled
      noReservedErrorSpace
    />
  );
};

export const InsulinSchemeInfo: React.FC<ConditionProps> = ({ condition }) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  return (
    <RadioFormInput<Condition>
      options={[
        {
          id: 'fake-single_injection',
          value: 'mono',
          label: t('diabetesForms.insulinMono'),
          hidden: condition.medicationSchema !== 'mono',
        },
        {
          id: 'fake-complex',
          value: 'multi',
          label: t('diabetesForms.insulinComplex'),
          hidden: condition.medicationSchema !== 'multi',
        },
        {
          id: 'fake-undef',
          value: undefined,
          label: t('condition.unspecified'),
          hidden: condition?.medicationSchema !== undefined,
        },
      ]}
      label={t('diabetesForms.insulinScheme')}
      labelStyle={styles.labelStyle}
      name="medicationSchema"
      disabled
      noReservedErrorSpace
    />
  );
};

export const TelemonitoringCriteriaInfo: React.FC<InfoProps> = ({
  carePlan,
}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  return (
    <RadioFormInput<CarePlan>
      options={[
        {
          id: 'fake-base',
          value: 'base',
          label: t('diabetesForms.criteriaBase'),
          tooltip: t('diabetesForms.criteriaBaseInfo'),
          hidden: carePlan?.telemonitoringTier !== 'base',
        },
        {
          id: 'fake-level_1',
          value: 'level1',
          label: t('diabetesForms.criteriaLevel1'),
          tooltip: t('diabetesForms.criteriaLevel1Info'),
          hidden: carePlan?.telemonitoringTier !== 'level1',
        },
        {
          id: 'fake-level_2',
          value: 'level2',
          label: t('diabetesForms.criteriaLevel2'),
          tooltip: t('diabetesForms.criteriaLevel2Info'),
          hidden: carePlan?.telemonitoringTier !== 'level2',
        },
        {
          id: 'fake-undef',
          value: undefined,
          label: t('condition.unspecified'),
          hidden: carePlan?.telemonitoringTier !== undefined,
        },
      ]}
      label={t('diabetesForms.criteria')}
      labelStyle={styles.labelStyle}
      name="telemonitoringCriteria"
      disabled
      noReservedErrorSpace
    />
  );
};

const TelemonitoringGoalsInfo: React.FC<Required<InfoProps>> = ({
  carePlan,
}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  const { data: practitioner } = Queries.practitioner.usePractitioner({});

  const isNurse = practitioner?.qualification === 'nurse';

  return (
    <Col>
      <div>
        <Typo type="subtitle" className={styles.labelStyle}>
          {t('diabetesForms.goalsShort')}
        </Typo>
        {!isNurse && <EditTherapeuticObjectives carePlan={carePlan} />}
      </div>
      {!carePlan.description && !carePlan.descriptionAttachment && (
        <Typo type="novalue">{t('diabetesForms.goalsNone')}</Typo>
      )}
      {carePlan.descriptionAttachment && (
        <FileView file={carePlan.descriptionAttachment} />
      )}
      {carePlan.description && (
        <Typo type="paragraph" className={styles.goals}>
          {carePlan.description}
        </Typo>
      )}
    </Col>
  );
};

const TelemonitoringDatesInfo: React.FC<Required<InfoProps>> = ({
  carePlan,
}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  return (
    <Col>
      <Typography variant="subtitle" className={styles.labelStyle}>
        {t('diabetesForms.dates')}
      </Typography>
      {
        <Typography variant="body">{`${toDateTime(
          carePlan.periodStart,
        ).toLocaleString()} - ${
          carePlan.periodEnd
            ? toDateTime(carePlan.periodEnd).toLocaleString()
            : '...'
        }`}</Typography>
      }
    </Col>
  );
};

const PrescriptionFileInfo: React.FC<Required<InfoProps>> = ({ carePlan }) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  return (
    <>
      <Col>
        <Typo type="subtitle" className={styles.labelStyle}>
          {t('diabetesForms.prescription')}
        </Typo>
        {carePlan?.prescription ? (
          <FileView file={carePlan.prescription}></FileView>
        ) : (
          <Typo type="novalue">{t('diabetesForms.prescriptionNone')}</Typo>
        )}
      </Col>
    </>
  );
};

const makeStyles = (theme: Theme) => ({
  grid: css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: ${theme.spacing(4)};
  `,
  labelStyle: css`
    font-size: ${FontSizes.body};
  `,
  goals: css`
    white-space: pre-wrap;
  `,
  noValue: css`
    color: ${theme.palette.text.disabled};
    font-style: italic;
  `,
  icon: css`
    height: ${FontSizes.body};
    width: ${FontSizes.body};
    margin-right: 0;
  `,
});
