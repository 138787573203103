import React from 'react';

import { css, cx } from '@emotion/css';
import { Theme, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import ReactCalendar, {
  CalendarProps as ReactCalendarProps,
  TileArgs,
} from 'react-calendar';

import { Icons } from '@/assets/icons';
import { useStyles } from '@/hooks/useStyles';
import { DateUtils } from '@/utils/date.ts';

type CalendarProps = ReactCalendarProps & {
  date: Date;
  onDateChange: (date: Date) => void;
  refDate?: Date;
  className?: string;
};

export function Calendar({
  date,
  onDateChange,
  refDate,
  className = '',
  ...rest
}: CalendarProps) {
  const { current: today } = React.useRef(
    DateTime.now().startOf('day').toJSDate(),
  );
  const styles = useStyles(makeStyles);
  const tileStyle = useStyles(makeTileStyles);

  return (
    <ReactCalendar
      className={cx(styles.calendar, className)}
      value={date}
      onClickDay={value => onDateChange(value)}
      navigationLabel={({ label }) => <NavigationLabel label={label} />}
      prevLabel={<Icons.chevronLeft className="calendar-custom-navigator" />}
      nextLabel={<Icons.chevronRight className="calendar-custom-navigator" />}
      prev2Label={null}
      next2Label={null}
      tileClassName={tile =>
        `calendar-custom-tile ${tileStyle({
          tile,
          date,
          today,
          refDate,
          disabledPredicate: rest.tileDisabled,
        })}`
      }
      defaultView="month"
      {...rest}
    />
  );
}

type TileStylesArgs = {
  tile: TileArgs;
  date: Date;
  today: Date;
  refDate?: Date;
  disabledPredicate?: (props: TileArgs) => boolean;
};

const NavigationLabel = ({ label }: { label: string }) => (
  <Typography
    variant="body"
    fontWeight="bold"
    className={css`
      text-align: center;
    `}
  >
    {label.capitalize()}
  </Typography>
);

const makeTileStyles =
  (theme: Theme) =>
  ({ tile, date, today, refDate, disabledPredicate }: TileStylesArgs) => {
    if (DateUtils.dateEquals(tile.date, refDate)) {
      return css`
        color: ${theme.palette.text.primary};
        background-color: ${theme.palette.primary.contrastText};
      `;
    }
    if (DateUtils.dateEquals(tile.date, date)) {
      return css`
        color: ${theme.palette.common.white};
        background-color: ${theme.palette.primary.light};
      `;
    }
    if (disabledPredicate) {
      if (disabledPredicate(tile)) {
        return css`
          opacity: 0.5;
        `;
      }
    } else if (tile.date < today) {
      return css`
        opacity: 0.5;
      `;
    }
    return css``;
  };

const makeStyles = () => ({
  calendar: css`
    .react-calendar__navigation__arrow {
      align-items: center;
      text-align: center;
    }
    .react-calendar__navigation__label {
      align-items: center;
      text-align: center;
    }
  `,
});
