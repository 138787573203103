import React from 'react';

import { useTheme } from '@mui/material';
import { Group } from '@visx/group';
import { scaleLinear } from '@visx/scale';
import { Line } from '@visx/shape';
import { DateTime, Interval } from 'luxon';

import { badgeHeight } from '@/components/data-representations/DailyGraph/Badge';
import { GlycemiaElements } from '@/components/data-representations/DailyGraph/GlycemiaElements';
import {
  BottomAxis,
  VerticalGrids,
} from '@/components/data-representations/DailyGraph/GraphDecorations';
import {
  FoodElements,
  InsulinElements,
  ReportsElements,
} from '@/components/data-representations/DailyGraph/OtherElements';
import {
  TextElements,
  bottomLineYPositions,
  bottomLineYPositionsCompact,
  heightBetweenLines,
} from '@/components/data-representations/DailyGraph/TextElements';
import {
  GlycemiaDataPoint,
  GlycemiaTypes,
} from '@/components/data-representations/DailyGraph/types';
import { Dataviz } from '@/models/Dataviz';
import { toDateTime } from '@/utils/date.ts';

const GAP = 20.0;
// Separates the data into subgroups when there are more than "gap" minutes between them.
// data: [{date:..., value:..}]
// gap: remove segment between to point separed of gap minutes
const split_data_gap = (data: GlycemiaDataPoint[], gap = GAP) => {
  if (!data || data.length == 0) {
    return [];
  }
  const split = [];
  for (let i = 0, j = 1; i < data.length - 1; i++, j++) {
    const d1 = data[i].date,
      d2 = data[j].date;
    split.push(
      Interval.fromDateTimes(toDateTime(d2), toDateTime(d1)).length('minutes') >
        gap,
    );
  }
  const data_splited = [];
  let data_splited_i = [data[0]];
  for (let i = 0; i < split.length; i++) {
    if (split[i]) {
      data_splited.push(data_splited_i);
      data_splited_i = [];
    }
    data_splited_i.push(data[i + 1]);
  }
  if (data_splited_i.length > 0) {
    data_splited.push(data_splited_i);
  }
  return data_splited;
};

const margin = { top: 25, left: 100, right: 100, bottom: 25 };

// x position of some graph elements

const leftGraphXPosition = 100;
const leftAxeXPosition = 11;

const getHour = (date: DateTime | string): number =>
  toDateTime(date).hour + toDateTime(date).minute / 60; // add minutes to hour

const getYPosition = (
  upperYPosition: number,
  size: 'compact' | 'full' = 'full',
) =>
  upperYPosition +
  (heightBetweenLines * (size === 'compact' ? 0.8 : 1)) / 2 -
  badgeHeight / 2;

type DailyGraphProps = {
  width: number;
  data: (Dataviz & GlycemiaTypes) | null;
  size?: 'compact' | 'full';
};

export const DailyGraph = ({ data, width, size = 'full' }: DailyGraphProps) => {
  const yPositions =
    size === 'compact' ? bottomLineYPositionsCompact : bottomLineYPositions;
  const height = yPositions.End;
  const theme = useTheme();
  const { parameters, glycemia, insulin, food, reports, glycemiaTypes } =
    data ?? {};

  const {
    glycemiaSensor = [],
    glycemiaReader = [],
    glycemiaManual = [],
  } = glycemiaTypes ?? {
    glycemiaReader: glycemia,
  };

  const [glycemiaSensor_splitted, setGlycemiaSensor_splitted] = React.useState<
    GlycemiaDataPoint[][]
  >([]);

  React.useEffect(() => {
    if (glycemiaSensor) {
      setGlycemiaSensor_splitted(split_data_gap(glycemiaSensor, GAP)); // 45 min
    }
  }, [glycemiaSensor]);

  const [maxGlucose, setMaxGlucose] = React.useState(0);
  const yScaleGlucose = scaleLinear({
    domain: [maxGlucose, 0],
    range: [
      yPositions.Event + badgeHeight / 2, // prevent overflow of the circle above and below Glycemia section
      yPositions.Glycemia,
    ],
  });

  const hoursLegends = Array.from(Array(26).keys())
    .map(e => e + ' h')
    .map(e => (e.length === 3 ? '0' + e : e));

  const xScale = scaleLinear({
    domain: [0, hoursLegends.length],
    range: [0, width - margin.right],
  });

  const rightAxeXPosition = xScale(24) + leftAxeXPosition;

  React.useEffect(() => {
    let isMounted = true;
    const UpdateLayoutBoundaries = () => {
      if (isMounted) {
        if (glycemia && parameters) {
          setMaxGlucose(
            Math.max(
              ...[
                Math.max(
                  ...(glycemiaReader || []).map(dataPoint => dataPoint.value),
                ),
                Math.max(
                  ...(glycemiaManual || []).map(dataPoint => dataPoint.value),
                ),
                Math.max(
                  ...(glycemiaSensor_splitted || [])
                    .flat()
                    .map(dataPoint => dataPoint.value),
                ),
                parameters.thresholdHypoglycemia,
                parameters.thresholdHyperglycemia,
                parameters.thresholdHyperglycemiaSevere,
                parameters.thresholdHypoglycemiaSevere,
              ],
            ),
          );
        }
      }
    };
    UpdateLayoutBoundaries();

    return () => {
      isMounted = false;
    };
  }, [
    data,
    maxGlucose,
    glycemiaReader,
    glycemiaManual,
    glycemiaSensor_splitted,
    parameters,
    glycemia,
  ]);

  return (
    <div style={{ position: 'relative' }}>
      <svg
        {...{
          width,
          height,
        }}
      >
        <TextElements {...{ width }} yPositions={yPositions} size={size} />

        <Group left={leftGraphXPosition}>
          <Line
            x1={leftAxeXPosition}
            x2={leftAxeXPosition}
            y2={height - margin.bottom}
            stroke={theme.palette.grey[100]}
          />
          <Line
            x1={rightAxeXPosition}
            x2={rightAxeXPosition}
            y2={height - margin.bottom}
            stroke={theme.palette.grey[100]}
          />
          <Group left={leftAxeXPosition}>
            <VerticalGrids {...{ height, margin, xScale }} />
            {reports && size == 'full' && (
              <ReportsElements
                {...{
                  reports,
                  xScale,
                  getHour,
                  top: getYPosition(yPositions.Start),
                }}
              />
            )}

            <GlycemiaElements
              {...{
                parameters,
                xScale,
                yScaleGlucose,
                glycemiaManual: glycemiaManual || [],
                glycemiaReader: glycemiaReader || [],
                glycemiaSensor: glycemiaSensor_splitted,
                getHour,
                marginLeft: leftGraphXPosition + leftAxeXPosition,
                small: false,
              }}
            />

            {food && size == 'full' && (
              <FoodElements
                {...{
                  food,
                  xScale,
                  getHour,
                  top: getYPosition(yPositions.Glycemia),
                }}
              />
            )}

            {insulin && (
              <InsulinElements
                {...{
                  insulin,
                  xScale,
                  getHour,
                  topBolus: getYPosition(yPositions.Food, size),
                  topBasal: getYPosition(yPositions.InsulinFast, size),
                  end: yPositions.InsulinSlow,
                }}
              />
            )}

            <BottomAxis {...{ height, margin, xScale }} />
          </Group>
        </Group>
      </svg>
    </div>
  );
};
