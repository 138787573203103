import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons';
import { useStyles } from '@/hooks/useStyles.ts';
import { EventCommentType } from '@/models/BGMLogbook';
import { toDateTime } from '@/utils/date.ts';

import { DetailComment } from './DetailComment';

export const EventCommentTranslations = {
  perceivedHypoglycemia: 'alertConfig.perceivedHypoglycemia',
  severeHypoglycemia: 'alertConfig.severeHypoglycemia',
  techAlert: 'alertConfig.technicalAlert',
  other: 'alertConfig.other',
  perceivedHyperglycemia: 'alertConfig.perceivedHyperglycemia',
} as const satisfies Record<EventCommentType['category'], string>;

export const EventComment = ({
  eventComment,
}: {
  eventComment: EventCommentType;
}) => {
  const time = toDateTime(eventComment.date).toLocaleString(
    DateTime.TIME_SIMPLE,
  );
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  const text = `${time}, ${t(EventCommentTranslations[eventComment.category])}, 
  ${eventComment.message}`;

  return (
    <DetailComment
      className={styles.chip}
      icon={<Icons.alertTriangle className={styles.icon} />}
      commentText={text}
      tooltipText={t('bgmLogBook.eventTooltip')}
    />
  );
};

const makeStyles = (theme: Theme) => ({
  chip: css`
    background-color: ${theme.palette.bgm.redBackground};
  `,
  icon: css`
    height: ${theme.spacing(8)};
    color: ${theme.palette.red.main};
  `,
});
