import { Dispatch, useReducer } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { TelemonitoringDrawer } from '@/components/sidesheet/TelemonitoringDrawer';
import { CarePlanData, PatientInfoData } from '@/models/CreatePatientModel';
import { PrescriptionGenerationHandler } from '@/models/PrescriptionGenerationModel';
import { Queries } from '@/queries/Queries';

import { PatientInformation } from './PatientInformation';
import { TelemonitoringInformation } from './TelemonitoringInformation';
import {
  AddPatientAction,
  AddPatientFormData,
  addPatientReducer,
} from './TelemonitoringReducer';
import { PrescriptionModal } from './prescriptionGeneration/PrescriptionModal';

type CreatePatientProps = {
  close: () => void;
};

type ContainerProps = {
  close: () => void;
  addPatientData: AddPatientFormData;
  dispatch: Dispatch<AddPatientAction>;
};

const Container = ({ close, addPatientData, dispatch }: ContainerProps) => {
  const navigate = useNavigate();
  const createPatientMutation = Queries.practitioner.useCreatePatient();
  const { data: carePlanDirectives } =
    Queries.practitioner.useCarePlanDirectives({});

  if (!carePlanDirectives) {
    return null;
  }

  const handlePrescription: PrescriptionGenerationHandler = ({
    prescription,
    patientWeight,
    patientBirthDate,
  }) => {
    const carePlan = addPatientData.carePlan as CarePlanData;
    createPatientMutation.mutate(
      {
        patient: {
          birthDate: patientBirthDate,
          weight: patientWeight,
          ...addPatientData.patientInfo,
        } as PatientInfoData,
        care_plan: {
          ...carePlan,
          prescription: prescription,
        },
        condition: {
          diabetes_type: carePlan.diabetesType,
          diabetes_medication: carePlan.insulinType,
        },
      },
      {
        onSuccess: data => {
          navigate(`/patients/${data.id}`);
        },
      },
    );
  };

  switch (addPatientData.step) {
    case 'PatientInfo':
      return (
        <PatientInformation
          close={close}
          patientInfo={addPatientData.patientInfo}
          onSubmit={data => {
            dispatch({
              type: 'TO_CAREPLAN',
              patientInfo: data,
              carePlan: addPatientData.carePlan,
            });
          }}
        />
      );
    case 'CarePlan':
    case 'Prescription':
      return (
        <>
          <TelemonitoringInformation
            onBack={data => {
              dispatch({
                type: 'BACK_TO_PATIENT_INFO',
                carePlan: data,
                patientInfo: addPatientData.patientInfo,
              });
            }}
            onSubmit={data =>
              dispatch({
                type: 'TO_PRESCRIPTION',
                carePlan: data,
                patientInfo: addPatientData.patientInfo,
              })
            }
            carePlanFormData={addPatientData.carePlan}
            patientInfo={addPatientData.patientInfo}
          />
          {addPatientData.step == 'Prescription' ? (
            <PrescriptionModal
              carePlanCreationDataDirectives={
                carePlanDirectives.carePlanCreationDataDirectives
              }
              telemonitoringTier={
                carePlanDirectives.telemonitoringTierMap[
                  addPatientData.carePlan.telemonitoringCriteria
                ]
              }
              patientInfo={addPatientData.patientInfo}
              duration={addPatientData.carePlan.duration}
              onPrescriptionReady={handlePrescription}
              onClose={() =>
                dispatch({
                  type: 'BACK_TO_CAREPLAN',
                  patientInfo: addPatientData.patientInfo,
                  carePlan: addPatientData.carePlan,
                })
              }
            />
          ) : null}
        </>
      );
    default:
      return null;
  }
};

export const CreatePatient = ({ close }: CreatePatientProps) => {
  const { t } = useTranslation();

  const [addPatientData, dispatch] = useReducer(addPatientReducer, {
    step: 'PatientInfo',
  });

  return (
    <TelemonitoringDrawer
      close={close}
      header={
        <Typography variant="h5">{t('pages.patients.addPatient')}</Typography>
      }
    >
      <Container
        close={close}
        addPatientData={addPatientData}
        dispatch={dispatch}
      />
    </TelemonitoringDrawer>
  );
};
