import React from 'react';

import { css, cx } from '@emotion/css';
import { Theme, Typography } from '@mui/material';
import { DateTime } from 'luxon';

import { useStyles } from '@/hooks/useStyles';
import { toDateTime } from '@/utils/date.ts';

type DataTooltip = {
  title: string;
  dataInputMethod: string;
  content?: string;
  date?: string;
  icon?: React.FC<React.SVGProps<SVGSVGElement>> | null;
};

export type DataTooltipProps =
  | DataTooltip
  | {
      data: DataTooltip[];
    };

export const DataTooltip: React.FC<DataTooltipProps> = props => {
  const styles = useStyles(makeStyles);

  if ('data' in props) {
    return (
      <div className={styles.container}>
        {props.data.map((d, idx) => (
          <SingleDataTooltip key={idx} {...d} />
        ))}
      </div>
    );
  }
  return <SingleDataTooltip {...props} />;
};

export const SingleDataTooltip: React.FC<DataTooltip> = ({
  title,
  content,
  date,
  dataInputMethod,
}) => {
  const styles = useStyles(makeStyles);

  return (
    <div className={styles.tooltip}>
      <Typography variant="body" className={cx(styles.item, styles.title)}>
        {title}
      </Typography>
      {content ? (
        <Typography
          variant="bodySmall"
          className={cx(styles.item, styles.content)}
        >
          {content}
        </Typography>
      ) : null}
      {date ? (
        <Typography variant="caption" className={styles.item}>
          {`${toDateTime(date).toLocaleString(DateTime.DATETIME_SHORT)} • ${dataInputMethod}`}
        </Typography>
      ) : null}
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: column;
    > *:not(:last-child) {
      border-bottom: 1px solid ${theme.palette.divider};
    }
    padding: ${theme.spacing(4)} ${theme.spacing(8)};
  `,
  tooltip: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${theme.spacing(2)};
    padding: ${theme.spacing(4)} 0;
  `,

  item: css`
    max-width: ${theme.spacing(128)};
    text-align: center;
  `,
  content: css`
    word-break: break-all;
  `,
  title: css`
    font-weight: bold;
  `,
});
