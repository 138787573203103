import React, { useMemo } from 'react';

import { css } from '@emotion/css';
import { Table, TableContainer, Theme } from '@mui/material';
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { CardBody } from '@/components/card/CardBody';
import { Loader } from '@/components/loading/Loader';
import { Typo } from '@/components/typography/Text';
import { useStyles } from '@/hooks/useStyles';
import { BGMData } from '@/models/BGMLogbook';
import { usePatientMonitoringContext } from '@/pages/patient-monitoring/PatientMonitoringState.tsx';
import { Queries } from '@/queries/Queries';

import { BGMTableBody } from './TableBody/BGMTableBody';
import { PrettyComment } from './TableBody/Comments/PrettyComment';
import { GlycemiaBadges } from './TableBody/GlycemiaBadges';
import { Insulin } from './TableBody/Insulin';
import { PrettyDate } from './TableBody/PrettyDate';
import { BGMTableHeader } from './TableHeader/BGMTableHeader';
import { HeaderWithTooltip } from './TableHeader/HeaderWithTooltip';

const columnHelper = createColumnHelper<BGMData>();
const duplicateHeader = (
  groupHeader: 'breakfast' | 'lunch' | 'dinner',
  className: string,
  t: TFunction<'translation', undefined>,
) => {
  const headerTypoType = 'paragraph';

  return columnHelper.group({
    id: groupHeader,
    header: () => (
      <Typo type={headerTypoType} className={className}>
        {t(`bgmLogBook.${groupHeader}`)}
      </Typo>
    ),
    columns: [
      columnHelper.accessor(`${groupHeader}.bloodSugarBefore`, {
        header: () => (
          <HeaderWithTooltip
            tootlipContent="bgmLogBook.glycemiaBefore"
            titleLocaleString="bgmLogBook.glycemiaBeforeHeader"
          />
        ),
        cell: info => <GlycemiaBadges data={info.getValue()} />,
        size: 50,
      }),
      columnHelper.accessor(`${groupHeader}.fastInsulin`, {
        header: () => (
          <HeaderWithTooltip
            tootlipContent="bgmLogBook.fastInsulin"
            titleLocaleString="bgmLogBook.fastInsulinHeader"
          />
        ),
        cell: info => <Insulin data={info.getValue()} />,
        size: 50,
      }),
      columnHelper.accessor(`${groupHeader}.bloodSugarAfter`, {
        header: () => (
          <HeaderWithTooltip
            tootlipContent="bgmLogBook.glycemiaAfter"
            titleLocaleString="bgmLogBook.glycemiaAfterHeader"
          />
        ),
        cell: info => <GlycemiaBadges data={info.getValue()} />,
        size: 50,
      }),
      columnHelper.accessor(`${groupHeader}.slowInsulin`, {
        header: () => (
          <HeaderWithTooltip
            tootlipContent="bgmLogBook.slowInsulin"
            titleLocaleString="bgmLogBook.slowInsulinHeader"
          />
        ),
        cell: info => <Insulin data={info.getValue()} />,
        size: 50,
      }),
    ],
  });
};

export const BGMContainerBody = () => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();
  const headerTypoType = 'paragraph';
  const request = usePatientMonitoringContext(state => state);

  const bgmRequest = Queries.diabetes.useInfiniteBGMLogbook({
    patientId: request.patient.id,
    from: request.to.minus({ days: 30 }).startOf('week'),
    to: request.to,
  });

  const data = useMemo(() => {
    return bgmRequest.isSuccess ? bgmRequest.data.pages.flat() : [];
  }, [bgmRequest.data, bgmRequest.isSuccess]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('date', {
        header: () => (
          <Typo type={headerTypoType} className={styles.header}>
            {t('bgmLogBook.date')}
          </Typo>
        ),
        cell: info => <PrettyDate date={info.getValue()} />,
        size: 90,
      }),
      duplicateHeader('breakfast', styles.header, t),
      duplicateHeader('lunch', styles.header, t),
      duplicateHeader('dinner', styles.header, t),
      columnHelper.group({
        id: 'night',
        header: () => (
          <Typo type={headerTypoType} className={styles.header}>
            {t('bgmLogBook.nightTime')}
          </Typo>
        ),
        columns: [
          columnHelper.accessor('night.bloodSugar', {
            header: () => (
              <Typo type={headerTypoType} className={styles.header}>
                {t('bgmLogBook.glycemia')}
              </Typo>
            ),
            cell: info => <GlycemiaBadges data={info.getValue()} />,
            size: 100,
          }),
          columnHelper.accessor('night.slowInsulin', {
            header: () => (
              <HeaderWithTooltip
                tootlipContent="bgmLogBook.slowInsulin"
                titleLocaleString="bgmLogBook.slowInsulinHeader"
              ></HeaderWithTooltip>
            ),
            cell: info => <Insulin data={info.getValue()} />,
            size: 50,
          }),
        ],
      }),
      columnHelper.accessor('comments', {
        header: () => (
          <Typo type={headerTypoType} className={styles.header}>
            {t('bgmLogBook.comment')}
          </Typo>
        ),
        cell: info => <PrettyComment comment={info.getValue()} />,
        size: 300,
      }),
    ],
    [styles.header, t],
  );

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const target = e.currentTarget;
    const diff = target.scrollHeight - target.scrollTop;
    const bottom = Math.abs(diff - target.clientHeight) < 2;
    if (bottom && !bgmRequest.isFetching && !bgmRequest.isLoading) {
      bgmRequest.fetchNextPage();
    }
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <CardBody>
      <TableContainer className={styles.container} onScroll={handleScroll}>
        <Table
          aria-label="a dense table"
          className={styles.table}
          size="small"
          stickyHeader
        >
          <BGMTableHeader table={table} />
          <BGMTableBody table={table} />
        </Table>
      </TableContainer>
      {(bgmRequest.isLoading || bgmRequest.isFetching) && (
        <Loader size="L" className={styles.loader} />
      )}
    </CardBody>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    border-radius: ${theme.spacing(6)};
    max-height: 80vh;
  `,
  scrollBar: css`
    height: 40vh;
  `,
  table: css`
    width: 100%;
  `,
  header: css`
    color: ${theme.palette.violet.main};
  `,
  loader: css`
    align-self: center;
  `,
});
