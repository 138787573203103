import { css } from '@emotion/css';
import { Button, DialogContent, DialogTitle, Theme } from '@mui/material';
import { DateTime } from 'luxon';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Form } from '@/components/form/Form';
import { useStyles } from '@/hooks/useStyles';
import { Queries } from '@/queries/Queries';
import { ButtonLoader } from '@/uiKit/ButtonLoader';
import { ControlledTextField } from '@/uiKit/input/ControlledTextField';
import { ControlledDateField } from '@/uiKit/molecules/ControlledDateField';

import { PartialPrescriptionGenerationData } from './GeneratePrescriptionReducer';

type PrescriptionInformationFormData = {
  weight: string;
  birthDate: DateTime;
};

type PrescriptionInformationProps = {
  onSubmit: (data: PrescriptionInformationFormData) => void;
  prescriptionInfo: PartialPrescriptionGenerationData;
  onBack: (data: PartialPrescriptionGenerationData) => void;
};
export const PrescriptionInformation = ({
  onSubmit,
  onBack,
  prescriptionInfo,
}: PrescriptionInformationProps) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const formContext = useForm<PrescriptionInformationFormData>({
    defaultValues: {
      weight: prescriptionInfo.weight || '',
      birthDate: prescriptionInfo.birthDate || undefined,
    },
  });

  const { status } = Queries.prescription.useGeneratePrescription();

  return (
    <>
      <Form
        onSubmit={onSubmit}
        id="PrescriptionGenerationForm"
        formContext={formContext}
      >
        <DialogTitle>{t('diabetesForms.prescriptionGeneration')}</DialogTitle>
        <DialogContent className={styles.container}>
          <div className={styles.row}>
            <ControlledTextField
              name="weight"
              required
              label={t('diabetesForms.weight')}
              variant="standard"
            />
          </div>
          <div className={styles.row}>
            <ControlledDateField
              label={t('profile.birthDate')}
              name="birthDate"
              variant="standard"
              required
              maxDate={DateTime.now()}
            />
          </div>
          <div className={styles.footer}>
            <Button
              variant="outlined"
              onClick={() =>
                onBack({ ...prescriptionInfo, ...formContext.getValues() })
              }
            >
              {t('button.cancel')}
            </Button>
            <ButtonLoader variant="contained" type="submit" status={status}>
              {t('button.next')}
            </ButtonLoader>
          </div>
        </DialogContent>
      </Form>
    </>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(12)};
    justify-content: center;
    align-items: center;
  `,
  row: css`
    display: flex;
    padding-left: ${theme.spacing(40)};
    padding-right: ${theme.spacing(40)};
  `,
  footer: css`
    display: flex;
    justify-content: space-between;
    gap: ${theme.spacing(40)};
  `,
});
