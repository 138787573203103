import React, { useEffect } from 'react';

import { css, cx } from '@emotion/css';
import { Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons';
import { Button } from '@/components/button/Button';
import { IconButton } from '@/components/button/IconButton';
import { Row } from '@/components/layout/Flex';
import { useStyles } from '@/hooks/useStyles';
import { Patient } from '@/models/PatientModel';
import { Queries } from '@/queries/Queries';
import { Tag } from '@/uiKit/tag/Tag';
import { DateUtils } from '@/utils/date.ts';

type PatientDetailHeaderProps = {
  patient?: Patient;
  className?: string;
};

export const PatientDetailHeader: React.FC<PatientDetailHeaderProps> = ({
  patient,
  className,
}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const { data: practitioner } = Queries.practitioner.usePractitioner({});

  if (!patient || !practitioner) {
    return null;
  }

  return (
    <Row className={cx(styles.container, className)}>
      <Row className={styles.infos}>
        <Typography variant="subtitleLarge" className={styles.subtitle}>
          {`${patient?.givenName ?? ''} ${patient?.familyName ?? ''}`.trim()}
        </Typography>
        {patient ? (
          <Typography variant="body">
            {patient.last_login
              ? `${t('patientNavigation.lastSeen')} ${DateUtils.humanDurationFromNow(patient.last_login)}`
              : t('patientNavigation.noFirstConnection')}
          </Typography>
        ) : null}
      </Row>
      {practitioner.qualification === 'nurse' ? (
        <NurseActions patientId={patient.id} className={styles.actions} />
      ) : (
        <DoctorActions patientId={patient.id} className={styles.actions} />
      )}
    </Row>
  );
};

type ActionsProps = {
  patientId: string;
  className?: string;
};
const NurseActions: React.FC<ActionsProps> = ({ patientId, className }) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  const deleteNotice = Queries.observations.useDeleteNotice();
  const createNotice = Queries.observations.useCreateNotice();
  useEffect(() => {
    deleteNotice.reset();
    createNotice.reset();
  }, [patientId]);

  const { data: notice } = Queries.practitioner.useNurseNotice(patientId);

  switch (true) {
    case notice == undefined:
      return (
        <div className={className}>
          <IconButton
            buttonType="primary"
            icon={Icons.forward}
            placement="left"
            status={createNotice.status}
            onClick={() =>
              createNotice.mutate({
                patientId: patientId,
              })
            }
          >
            {t('patientNavigation.notice.notifyDoctor')}
          </IconButton>
        </div>
      );
    default:
      return (
        <div className={className}>
          <Tag
            color="neutral"
            label={t('patientNavigation.notice.notified')}
            icon={<Icons.forward />}
            className={styles.tag}
          />
          <Button
            buttonType="secondary"
            status={deleteNotice.status}
            onClick={() =>
              deleteNotice.mutate({
                patientId: patientId,
                noticeId: notice.id,
              })
            }
          >
            {t('patientNavigation.notice.cancelNotify')}
          </Button>
        </div>
      );
  }
};

const DoctorActions: React.FC<ActionsProps> = ({ patientId, className }) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const mitigateNotice = Queries.observations.useMitigateNotice();
  const dismissAlert = Queries.alerts.useDismissAlert();
  const { data: alerts } = Queries.practitioner.useListPatientAlerts(patientId);
  const { data: notice } = Queries.practitioner.useNurseNotice(patientId);

  useEffect(() => {
    mitigateNotice.reset();
  }, [patientId, alerts]);

  if (!alerts || !notice) {
    return null;
  }

  return (
    <div className={className}>
      <Tag
        color="blue"
        label={t('patientNavigation.notice.notified')}
        icon={<Icons.stethoscope />}
        className={cx(styles.tag, { [styles.fadeOut]: !notice })}
      />
      <Button
        disabled={!notice}
        className={cx({ [styles.fadeOut]: !notice })}
        buttonType="primary"
        status={mitigateNotice.status}
        onClick={() =>
          notice &&
          mitigateNotice.mutate(
            {
              patientId: patientId,
              noticeId: notice.id,
            },
            {
              onSuccess: () => {
                alerts.forEach(alert =>
                  dismissAlert.mutate({
                    patientId: patientId,
                    alertId: alert.id,
                  }),
                );
              },
            },
          )
        }
      >
        {t('patientNavigation.notice.mitigateNotify')}
      </Button>
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    justify-content: space-between;
    align-items: center;
  `,
  infos: css`
    flex: 1 1;
    align-items: baseline;
  `,
  actions: css`
    margin-left: ${theme.spacing(8)};
    display: flex;
    flex-direction: row;
    flex: 0 1 auto;
    flex-wrap: wrap;
    gap: ${theme.spacing(8)};
    align-items: center;
    justify-content: flex-end;
  `,
  subtitle: css`
    margin-right: ${theme.spacing(4)};
  `,
  fadeOut: css`
    visibility: hidden;
    opacity: 0;
    display: none;
    transition:
      visibility 0.5s,
      opacity 0.5s;
  `,
  userPicture: css`
    width: ${theme.spacing(30)};
    height: ${theme.spacing(30)};
    margin-right: ${theme.spacing(8)};
  `,
  tag: css`
    padding: ${theme.spacing(8)};
  `,
});
