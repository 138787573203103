import React from 'react';

import { css, cx } from '@emotion/css';
import { Theme, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Icons } from '@/assets/icons';
import { IconButton } from '@/components/button/IconButton';
import { Card } from '@/components/card/Card';
import { CardBody } from '@/components/card/CardBody';
import { CardTitle } from '@/components/card/CardTitle';
import { useStyles } from '@/hooks/useStyles';
import { usePatientMonitoringContext } from '@/pages/patient-monitoring/PatientMonitoringState.tsx';
import { Queries } from '@/queries/Queries';
import { DateUtils } from '@/utils/date';

type PatientSummaryCardProps = {
  className?: string;
};

export const PatientSummaryCard: React.FC<PatientSummaryCardProps> = ({
  className,
}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const navigate = useNavigate();
  const patient = usePatientMonitoringContext(state => state.patient);

  return (
    <Card className={className} elevation={0}>
      <CardTitle style={{ justifyContent: 'space-between' }}>
        <Typography variant="h6">
          {t('pages.patientMonitoring.patient.title')}
        </Typography>
        <div>
          <IconButton
            style={{ marginRight: 0, marginLeft: 0, paddingRight: 0 }}
            icon={Icons.externalLink}
            buttonType="link"
            placement="left"
            onClick={() => navigate(`/patients/${patient.id}/medical-file`)}
          />
        </div>
      </CardTitle>
      <CardBody className={styles.body}>
        {patient.birthDate ? <Age birthDate={patient.birthDate} /> : null}
        <DiabetesTypeComponent patientId={patient.id} />
        <InjectionSchemeComponent patientId={patient.id} />
      </CardBody>
    </Card>
  );
};

type ConditionProps = {
  patientId: string;
};

type AgeProps = {
  birthDate: DateTime;
};

const Age: React.FC<AgeProps> = ({ birthDate }) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  return (
    <Typography variant="bodySmall" className={styles.text}>
      {t('pages.patientMonitoring.patient.age', {
        age: DateUtils.age(birthDate),
      })}
    </Typography>
  );
};

const diabetesTypeMapping = {
  type1: 'pages.patientMonitoring.patient.diabetesType1',
  type2: 'pages.patientMonitoring.patient.diabetesType2',
  gestational: 'pages.patientMonitoring.patient.gestationalDiabetes',
};

const DiabetesTypeComponent: React.FC<ConditionProps> = ({ patientId }) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  const { data: condition } = Queries.condition.useCondition(patientId);

  return condition ? (
    <Typography variant="bodySmall" className={styles.text}>
      {t(diabetesTypeMapping[condition.type])}
    </Typography>
  ) : (
    <Typography variant="bodySmall" className={cx(styles.text, styles.noValue)}>
      {t('pages.patientMonitoring.patient.unspecifiedDiabetesType')}
    </Typography>
  );
};

const insulinSchemeMapping = {
  mono: 'pages.patientMonitoring.patient.monoInjection',
  multi: 'pages.patientMonitoring.patient.multiInjection',
  oral: 'pages.patientMonitoring.patient.oralTreatment',
};

const InjectionSchemeComponent: React.FC<ConditionProps> = ({ patientId }) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  const { data: condition } = Queries.condition.useCondition(patientId);

  return condition ? (
    <Typography variant="bodySmall" className={styles.text}>
      {t(insulinSchemeMapping[condition.medicationSchema])}
    </Typography>
  ) : (
    <Typography variant="bodySmall" className={cx(styles.text, styles.noValue)}>
      {t('pages.patientMonitoring.patient.unspecifiedInsulinScheme')}
    </Typography>
  );
};

const makeStyles = (theme: Theme) => ({
  body: css``,
  text: css`
    margin: ${theme.spacing(2)} 0;
  `,
  noValue: css`
    color: ${theme.palette.text.disabled};
    font-style: italic;
  `,
  footer: css`
    justify-content: flex-end;
    padding-bottom: ${theme.spacing(8)};
  `,
});
