import React from 'react';

import { css, cx } from '@emotion/css';
import { Theme } from '@mui/material';
import { SystemZone } from 'luxon';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons';
import { TextToast } from '@/components/toast/TextToast';
import { usePatientIdFromURL } from '@/hooks/usePatientIdFromURL';
import { useStyles } from '@/hooks/useStyles';
import { SelfPatient } from '@/models/SelfPatientModel';
import { Queries } from '@/queries/Queries';

const noFileChosen = (file: FileList | null): file is null =>
  file === null || file.length === 0;

type FileUploadProps = {
  patient: SelfPatient;
};

export const FileUpload: React.FC<FileUploadProps> = ({ patient }) => {
  const { t } = useTranslation();
  const patientId = usePatientIdFromURL();
  const uploadFile = Queries.diabetes.useUploadFile();
  const [file, setFile] = React.useState<FileList | null>(null);
  const isLoading = uploadFile.isPending;
  const disabled = noFileChosen(file) || uploadFile.isPending;
  const styles = useStyles(makeStyles);

  const handleSubmit = async (evt: React.ChangeEvent<HTMLFormElement>) => {
    evt.preventDefault();
    if (file === null) return;

    const fileToUpload = file[0];

    uploadFile.mutate(
      {
        timezone: SystemZone.instance.name,
        patientId: patientId ?? patient.id,
        file: fileToUpload,
      },
      {
        onSuccess: () => {
          setFile(null);
          TextToast.info(t('pages.addMeasurementFile.success'));
        },
        onError: () => {
          TextToast.error(t('pages.addMeasurementFile.error'));
        },
      },
    );
  };

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (evt.target.files === null || evt.target.files.length === 0) {
      setFile(null);
    } else {
      setFile(evt.target.files);
    }
  };

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <label
        htmlFor="file"
        className={[
          styles.labelFile,
          noFileChosen(file) ? styles.labelPrimary : styles.labelSecondary,
        ].join(' ')}
      >
        {t('pages.addMeasurementFile.chooseFile')}
      </label>
      <input
        id="file"
        className={styles.inputFile}
        type="file"
        accept=".csv"
        onChange={handleChange}
      />
      {noFileChosen(file) ? (
        <p>{t('pages.addMeasurementFile.noFileChosen')}</p>
      ) : (
        <div className={styles.fileName}>
          <Icons.trash type="button" onClick={() => setFile(null)} />
          <p>{file[0].name}</p>
        </div>
      )}
      <button
        type="submit"
        disabled={disabled}
        className={cx({
          [styles.labelPrimary]: !disabled,
          [styles.labelSecondary]: disabled,
          [styles.btnSubmit]: true,
        })}
      >
        {t(isLoading ? 'button.pending' : 'button.submit')}
      </button>
    </form>
  );
};

const makeStyles = (theme: Theme) => ({
  fileName: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    svg {
      fill: currentColor;
      color: ${theme.palette.primary.main};
      height: 1rem;
      width: 1rem;
      cursor: pointer;
    }
  `,
  form: css`
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 2rem;
  `,
  inputFile: css`
    display: none;
  `,
  labelFile: css``,
  labelPrimary: css`
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.common.white};
    text-align: center;
    padding: ${theme.spacing(4, 8)};
    border-radius: ${theme.spacing(2)};
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  `,
  labelSecondary: css`
    color: ${theme.palette.text.primary};
    text-align: center;
    padding: ${theme.spacing(4, 8)};
    border-radius: ${theme.spacing(2)};
    outline: solid 1px ${theme.palette.primary.main};
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  `,
  btnSubmit: css`
    :disabled {
      cursor: default;
    }
  `,
});
