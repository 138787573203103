import React from 'react';

import { css } from '@emotion/css';
import { Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons';
import { MenuActionTooltip } from '@/components/floating/MenuTooltip';
import { useStyles } from '@/hooks/useStyles';

export type Periodicity = {
  durationInDays: number;
  label: string;
  index: number;
};

export type Periodicities = Periodicity[];

type PeriodSelectorProps = {
  value: number;
  onChangePeriodicity: (periodicity: Periodicity) => void;
  periodicities: Periodicities;
};

export const PeriodSelector: React.FC<PeriodSelectorProps> = ({
  value,
  onChangePeriodicity,
  periodicities,
}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  const handleClick = (selected: number) => {
    onChangePeriodicity(periodicities[selected]);
    close();
  };
  return (
    <MenuActionTooltip
      placement="bottom-start"
      className={styles.container}
      wrapperClassName={styles.containerWrapper}
      actions={periodicities.slice(0, -1).map((periodicity, idx) => (
        <button
          key={idx}
          onClick={() => handleClick(idx)}
          className={styles.dropdownItem}
        >
          <Icons.calendar />
          <Typography variant="body" className={styles.itemText}>
            {t(periodicity.label)}
          </Typography>
          {value === idx && <Icons.check className={styles.selected} />}
        </button>
      ))}
    >
      <div className={styles.select}>
        <Typography variant="body" className={styles.selectText}>
          {t(periodicities[value].label)}
        </Typography>
        <Icons.arrowDown />
      </div>
    </MenuActionTooltip>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    align-self: stretch;
    align-items: stretch;
  `,
  containerWrapper: css`
    display: flex;
    align-items: stretch;
  `,
  select: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${theme.spacing(2)} ${theme.spacing(3)};
    width: ${theme.spacing(128)};
    text-align: center;
    background-color: ${theme.palette.common.white};
  `,
  selectText: css`
    flex-grow: 1;
  `,
  dropdownItem: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${theme.spacing(4)};
    width: ${theme.spacing(128)};
    padding: ${theme.spacing(4)} ${theme.spacing(8)};
    cursor: pointer;
    &:hover {
      background-color: ${theme.palette.background.default};
    }
  `,
  itemText: css`
    text-align: left;
    flex: 1 1 0;
  `,
  selected: css`
    fill: ${theme.palette.primary.main};
    height: ${theme.spacing(8)};
    width: ${theme.spacing(8)};
  `,
});
