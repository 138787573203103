import {
  DecoderFunction,
  field,
  number,
  string,
} from 'typescript-json-decoder';

import {
  deepField,
  nullOrUndef,
  recordWithContext,
  stringUnion,
  withDefaultValue,
} from '@/utils/decoderUtils';

/**
 *  {
 *   PUMP = 'pump',
 *   READER = 'reader',
 *   SENSOR = 'sensor',
 *   MANUAL = 'manual',
 *   REMOTE_MONITORING = 'remote monitoring',
 * }
 */
export type DeviceType =
  | 'pump'
  | 'reader'
  | 'sensor'
  | 'manual'
  | 'remote monitoring';

export type MedicalDevice = {
  id: number;
  serialNumber?: string;
  name: string;
  manufacturer: string;
  typeId: number;
  deviceType: DeviceType;
  lastSync: string;
};

export const medicalDeviceDecoder: DecoderFunction<MedicalDevice> =
  recordWithContext('MedicalDevice', {
    id: number,
    serialNumber: field('serial_number', nullOrUndef(string)),
    name: deepField('device_type.name', string),
    manufacturer: deepField('device_type.manufacturer', string),
    typeId: deepField('device_type.id', number),
    deviceType: deepField(
      'device_type.device_type',
      withDefaultValue(
        stringUnion<DeviceType>(
          'pump',
          'reader',
          'sensor',
          'manual',
          'remote monitoring',
        ),
        'manual',
      ),
    ),
    lastSync: field('last_sync', (value: unknown) => {
      // We need to convert the date to ISO format as the API returns it in a different format
      // API fix is in progress
      const data = string(value);
      const date = new Date(data);
      if (isNaN(date.getTime())) {
        throw new Error('Invalid date');
      }
      return date.toISOString();
    }),
  });
