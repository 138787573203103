import React from 'react';

import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/button/Button';
import { Card } from '@/components/card/Card';
import { CardBody } from '@/components/card/CardBody';
import { TextCardTitle } from '@/components/card/CardTitle';
import { useStyles } from '@/hooks/useStyles';
import { useKeycloak } from '@/keycloak';

export type SecurityProps = {
  className?: string;
};

export const Security: React.FC<SecurityProps> = ({ className }) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const keycloak = useKeycloak();

  return (
    <Card className={className} elevation={0}>
      <TextCardTitle
        id="security-title"
        title={t('pages.account.security.title')}
      />
      <CardBody>
        <Button
          className={styles.button}
          buttonType="secondary"
          onClick={() => keycloak.login({ action: 'UPDATE_PASSWORD' })}
        >
          {t('pages.account.security.updatePassword')}
        </Button>
      </CardBody>
    </Card>
  );
};

const makeStyles = () => ({
  button: css`
    width: fit-content;
  `,
});
