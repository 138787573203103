import { css } from '@emotion/css';
import { Theme } from '@mui/material';
import { DateTime } from 'luxon';

import { useStyles } from '@/hooks/useStyles';

type separatorType = {
  date: DateTime;
};

export const DateSeparator = ({ date }: separatorType) => {
  const styles = useStyles(makeStyle);

  const formatDate: string = date.toLocaleString(DateTime.DATE_HUGE);

  return (
    <div className={styles.container}>
      <div className={styles.separator}></div>
      <span className={styles.date}>
        <span className={styles.dateText}>{formatDate}</span>
      </span>
    </div>
  );
};

const makeStyle = (theme: Theme) => ({
  container: css`
    display: flex;
    width: 100%;
    height: ${theme.spacing(8)};
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    position: relative;
  `,
  separator: css`
    width: 100%;
    height: ${theme.spacing(1)};
    flex-shrink: 0;
    background: ${theme.palette.background.paper};
    position: absolute;
  `,
  date: css`
    display: inline-flex;
    height: ${theme.spacing(8)};
    padding: 0 ${theme.spacing(2)};
    justify-content: center;
    align-items: center;
    gap: ${theme.spacing(4)};
    flex-shrink: 0;
    background: ${theme.palette.common.white};
    position: absolute;
    z-index: 2;
  `,
  dateText: css`
    text-transform: capitalize;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.2px;
  `,
});
