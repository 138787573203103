import { useMemo } from 'react';

import { DateTime } from 'luxon';

import { useQueryString } from '@/hooks/query/useQueryString.ts';
import { toDateTime } from '@/utils/date.ts';

export const useQueryDate = (): DateTime<true> => {
  const { date } = useQueryString();

  return useMemo(() => {
    if (date) {
      const parsedDate = toDateTime(date);
      if (parsedDate.isValid) {
        return parsedDate;
      }
    }
    return DateTime.now();
  }, [date]);
};
