import { DateTime } from 'luxon';

import {
  Periodicities,
  Periodicity,
} from '@/components/period-picker/PeriodPicker/PeriodSelector.tsx';
import { QueryDateRange } from '@/hooks/query/useQueryDateRange.ts';
import { DateUtils } from '@/utils/date.ts';

export const periodicities: Periodicities = [
  {
    durationInDays: 7,
    label: 'periodicity.oneWeek',
    index: 0,
  },
  {
    durationInDays: 14,
    label: 'periodicity.twoWeeks',
    index: 1,
  },
  {
    durationInDays: 30,
    label: 'periodicity.oneMonth',
    index: 2,
  },
  {
    durationInDays: 90,
    label: 'periodicity.threeMonths',
    index: 3,
  },
  {
    durationInDays: 180,
    label: 'periodicity.sixMonths',
    index: 4,
  },
  {
    durationInDays: 0,
    label: 'periodicity.customize',
    index: 5,
  },
];

export const getCurrentPeriodicity = (
  from: DateTime,
  to: DateTime,
): Periodicity => {
  return (
    periodicities.find(
      e => e.durationInDays == DateUtils.compareDates(to, from),
    ) || periodicities[1]
  );
};

export const getDateRangeAndPeriodicity = (dateRange?: QueryDateRange) => {
  if (!dateRange) {
    return {
      from: DateTime.now().minus({ days: periodicities[1].durationInDays }),
      to: DateTime.now(),
      periodicity: periodicities[1],
    };
  }

  const from = dateRange.from;
  const to = dateRange.to;

  const periodicity = getCurrentPeriodicity(from, to);

  return { from, to, periodicity };
};
